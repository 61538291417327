import React from 'react';
import './video-member.scss';

interface VideoMemberProps {
    requireSrc?: string;
    requireSrcWebM?: string;
    className?: string;
    country: string;
}

const VideoMember: React.FC<VideoMemberProps> = ({
    requireSrc,
    className,
    country,
    requireSrcWebM,
}) => {
    return (
        <div className={`VideoMember ${className ? className : ''}`}>
            <video
                className="tabs-video lozad"
                width="100%"
                height="100%"
                autoPlay
                loop
                muted
                playsInline
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
            >
                {requireSrcWebM && <source src={requireSrcWebM} type="video/webm" />}
                {requireSrc && <source src={requireSrc} type="video/mp4" />}
            </video>

            <div className="country text-body-primary-font text-white">{country}</div>
        </div>
    );
};

export default VideoMember;
