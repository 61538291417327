import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoBrand } from '../../../../Layout/Components/logo-brand';
import { theme } from '../../../../Style/theme';
import vogueLogo from '../../../../Assets/Img/Home/vogue-logo.webp';
import vogueStatic from '../../../../Assets/Img/Home/vogue-static.png';
import vogueVideoMobile from '../../../../Assets/MP4/vogue-business-mobile.mp4';
import vogueVideo from '../../../../Assets/MP4/vogue.mp4';

import './index.scss';
import { useInView } from 'framer-motion';

const VogueSection = () => {
    const videoRef = useRef(null);
    const { t } = useTranslation();
    const isInView = useInView(videoRef, { once: true, amount: 0.9 });

    return (
        <section ref={videoRef} className="VogueSection" id="VogueSection">
            <div className={`vogue-cover top ${isInView ? 'vogue-fade-out' : ''}`} />
            <video
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
                className="vogue-video"
            >
                <source media="(max-width: 799px)" src={vogueVideoMobile} type="video/mp4" />
                <source media="(min-width: 800px)" src={vogueVideo} type="video/mp4" />
                <source src={vogueVideoMobile} type="video/mp4" />
            </video>
            <div className="vogue-content-overlay">
                <div className={`vogue-content ${isInView ? 'vogue-fade-in' : ''}`}>
                    <h4 className="vogue-content-overlay-heading">
                        <LogoBrand fill={theme['white']} width={100} height={28} />
                        <span className="">{t('VogueSection.VogueSectionPartnering')}</span>
                    </h4>
                    <img
                        src={vogueLogo}
                        alt="Vogue business logo"
                        className="vogue-logo"
                        loading="lazy"
                    />
                    <div className="vogue-description">
                        <p>{t('VogueSection.VogueSectionDescription')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('VogueSection.VogueSectionDescription2'),
                            }}
                        />
                    </div>
                </div>
            </div>
            <picture>
                <source media="(min-width: 800px)" srcSet={vogueStatic} />
                <img
                    src={vogueStatic}
                    alt="Vogue business logo with see through letters"
                    className={`vogue-overlay ${isInView ? 'vogue-fade-out' : ''}`}
                    loading="lazy"
                />
            </picture>
            <div className={`vogue-cover bottom ${isInView ? 'vogue-fade-out' : ''}`} />
        </section>
    );
};

export default VogueSection;
