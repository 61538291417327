import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import PreviewRemote from './../../../../Assets/Img/LifeAtSpreeAI/preview_remote.webp';
import WrapperSection from '../../../../Components/WrapperSection';
import Copydeck from '../../../../i18n/Copydeck';

const LifeAtSpreeAISectionRemote = () => {
    const { t } = useTranslation();

    return (
        <div className="LifeAtSpreeAISectionRemote">
            <div className="wrapper">
                <div className="left">
                    <WrapperSection.Title htmlTag="h2" className="title">
                        {Copydeck.lifeAtSpreeAipageRemoteTitle}
                    </WrapperSection.Title>

                    <h4 className="text-body">{t('LifeAtSpreeAIPage.remote_description')}</h4>
                </div>

                <div className="right">
                    <img src={PreviewRemote} alt="Remote at SpreeAI" width={578} loading="lazy" />
                </div>
            </div>
        </div>
    );
};

export default LifeAtSpreeAISectionRemote;
