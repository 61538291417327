import { useState, useEffect } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import * as Tabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { CaretDown } from '@phosphor-icons/react';

import styles from './styles/tabbed-faqs.module.scss';
import sharedStyles from '../index.module.scss';

import useMeasure from 'react-use-measure';

interface TabbedFAQsProps {
    faqList: {
        id: string;
        title: string;
        faqs: {
            question: string;
            answer: string;
            hasCustomHTML?: boolean;
        }[];
    }[];
}

export const TabbedFAQs = ({ faqList }: TabbedFAQsProps) => {
    const [activeTab, setActiveId] = useState('');
    const [selectedTab, setSelectedTab] = useState(faqList[0].id);
    const [minHeight, setMinHeight] = useState(0);
    const [ref, bounds] = useMeasure({});

    const handleSetActiveId = (id: string) => {
        setActiveId(id);
    };

    const handleSetSelectedTab = (id: string) => {
        setSelectedTab(id);
    };

    useEffect(() => {
        if (minHeight) return;
        const maxFaqs = faqList.reduce((acc, faq) => {
            return faq.faqs.length > acc ? faq.faqs.length : acc;
        }, 0);

        setMinHeight(maxFaqs * bounds.height);
    }, [ref]);

    return (
        <Tabs.Root defaultValue={selectedTab} value={selectedTab} className={styles.tabsRoot}>
            <Tabs.List className={styles.tabsList}>
                {faqList.map((faq) => (
                    <Tabs.Trigger
                        key={`trigger-${faq.id}`}
                        onClick={() => handleSetSelectedTab(faq.id)}
                        onMouseMove={() => handleSetActiveId(faq.id)}
                        onMouseLeave={() => handleSetActiveId('')}
                        className={styles.tab}
                        value={faq.id}
                        style={{
                            WebkitTapHighlightColor: 'transparent',
                        }}
                    >
                        <AnimatePresence initial={false} mode="popLayout">
                            {activeTab === faq.id && (
                                <motion.div
                                    layoutId="bubble"
                                    className={styles.bubble}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 0.8 }}
                                    exit={{ opacity: 0 }}
                                    style={{ borderRadius: '8px' }}
                                    transition={{
                                        type: 'spring',
                                        bounce: 0,
                                        duration: 0.4,
                                    }}
                                />
                            )}
                        </AnimatePresence>
                        <span
                            style={{
                                position: 'relative',
                                zIndex: 10,
                            }}
                        >
                            {faq.title}
                        </span>
                        {selectedTab === faq.id ? (
                            <motion.div
                                layoutId="line"
                                className={styles.line}
                                transition={{
                                    type: 'spring',
                                    bounce: 0,
                                    duration: 0.4,
                                }}
                            />
                        ) : null}
                    </Tabs.Trigger>
                ))}
            </Tabs.List>
            <div style={{ minHeight }}>
                {faqList.map((faqObj) => (
                    <Tabs.Content key={`content-${faqObj.id}`} value={faqObj.id}>
                        <Accordion.Root className={styles.accordionRoot} type="single" collapsible>
                            {faqObj.faqs.map((faq, index) => (
                                <Accordion.Item
                                    ref={ref}
                                    key={`${faqObj.id}-${index}`}
                                    value={`item-${index}`}
                                    className={styles.accordionItem}
                                >
                                    <Accordion.Header className={styles.accordionHeader}>
                                        <Accordion.Trigger className={styles.accordionTrigger}>
                                            <h3>{faq.question}</h3>
                                            <CaretDown
                                                className={styles.accordionChevron}
                                                color="#2b2b2b"
                                                size={28}
                                            />
                                        </Accordion.Trigger>
                                    </Accordion.Header>
                                    <Accordion.Content className={styles.accordionContent}>
                                        {faq.hasCustomHTML ? (
                                            <p
                                                className={sharedStyles.body1}
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.answer,
                                                }}
                                            ></p>
                                        ) : (
                                            <p className={sharedStyles.body1}>{faq.answer}</p>
                                        )}
                                    </Accordion.Content>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </Tabs.Content>
                ))}
            </div>
        </Tabs.Root>
    );
};
