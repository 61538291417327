export const AccountDeletedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="84"
            viewBox="0 0 83 84"
            fill="none"
        >
            <path
                d="M76.7082 23C79.7647 28.652 81.5 35.1234 81.5 42C81.5 64.0914 63.5914 82 41.5 82C19.4086 82 1.5 64.0914 1.5 42C1.5 19.9086 19.4086 2 41.5 2C49.2368 2 56.4605 4.19653 62.5817 8"
                stroke="#2EB67D"
                stroke-width="3"
            />
            <path
                d="M41.5625 48.6875C48.4661 48.6875 54.0625 43.161 54.0625 36.3438C54.0625 29.5265 48.4661 24 41.5625 24C34.6589 24 29.0625 29.5265 29.0625 36.3438C29.0625 43.161 34.6589 48.6875 41.5625 48.6875Z"
                stroke="#62C89D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.5 60.8368C23.381 57.1781 26.2511 54.1053 29.7923 51.9589C33.3335 49.8125 37.4074 48.6763 41.5625 48.6763C45.7176 48.6763 49.7915 49.8125 53.3327 51.9589C56.8739 54.1053 59.744 57.1781 61.625 60.8368"
                stroke="#62C89D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M56 18H71"
                stroke="#62C89D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
