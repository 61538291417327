import React, { useCallback, useRef } from 'react';
import { useStores } from '../../Hooks/use-stores';
import { SupportedLanguage } from '../../Stores/LanguageStore';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

interface WrapperVideoProps {
    /**alt: Must be copydeck key for translations */
    alt: string;
    className?: string;
    classNameOnVideo?: string;
    style?: React.CSSProperties;
    width?: number;
    videoRef?: React.RefObject<HTMLVideoElement>;
    controls?: boolean;
    muted?: boolean;
    poster?: string;
}

interface WrapperVideoMultiLanguageProps extends WrapperVideoProps {
    /**src: Provide the image path for each language, and if there is only one image intended for all languages, use the uniqueSrc. */
    src: {
        en: {
            mobile?: {
                webm?: string;
                mp4?: string;
            };
            desktop: {
                webm?: string;
                mp4?: string;
            };
        };
        fr?: {
            mobile?: {
                webm?: string;
                mp4: string;
            };
            desktop: {
                webm?: string;
                mp4: string;
            };
        };
        it?: {
            mobile?: {
                webm?: string;
                mp4: string;
            };
            desktop: {
                webm?: string;
                mp4: string;
            };
        };
        es?: {
            mobile?: {
                webm?: string;
                mp4: string;
            };
            desktop: {
                webm?: string;
                mp4: string;
            };
        };
    };
}

interface WrapperVideoUniqueProps extends WrapperVideoProps {
    /**uniqueSrc: Path for all languages */
    uniqueSrc: string;
}

const WrapperVideo: React.FC<WrapperVideoMultiLanguageProps | WrapperVideoUniqueProps> = (
    props
) => {
    const { languageStore } = useStores();
    const [currentLanguage, setCurrentLanguage] = React.useState<SupportedLanguage>(
        SupportedLanguage.EN
    );

    const { windowWidth } = useWindowDimensions();
    const isMobile = windowWidth < 700;
    const localRef = useRef(null);

    const [currentVideoToDisplay, setCurrentVideoToDisplay] = React.useState<{
        webm: string;
        mp4: string;
    }>({
        webm:
            ((props as any).src as any)?.[languageStore.currentLanguage || SupportedLanguage.EN]
                ?.desktop?.webm || null,
        mp4:
            ((props as any).src as any)?.[languageStore.currentLanguage || SupportedLanguage.EN]
                ?.desktop?.mp4 || null,
    });

    const getCurrentLanguageForImage = useCallback(() => {
        if (!languageStore?.currentLanguage) {
            return;
        }

        setCurrentLanguage(languageStore.currentLanguage);
        setCurrentVideoToDisplay({
            webm: isMobile
                ? ((props as any).src as any)?.[
                      (languageStore.currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()
                  ]?.mobile?.webm || null
                : ((props as any).src as any)?.[
                      (languageStore.currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()
                  ]?.desktop?.webm || null,
            mp4: isMobile
                ? ((props as any).src as any)?.[
                      (languageStore.currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()
                  ]?.mobile?.mp4 || null
                : ((props as any).src as any)?.[
                      (languageStore.currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()
                  ]?.desktop?.mp4 || null,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore?.currentLanguage, isMobile]);

    React.useEffect(() => {
        if (!languageStore?.currentLanguage) {
            return;
        }

        getCurrentLanguageForImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore?.currentLanguage, isMobile]);

    React.useEffect(() => {
        if (!currentVideoToDisplay.mp4 && !currentVideoToDisplay.webm) {
            return;
        }

        setTimeout(() => {
            (localRef as any).current?.load();
        }, 200);
    }, [currentVideoToDisplay]);

    if ('uniqueSrc' in props) {
        return null;
    }

    try {
        if (!(props.src as any)?.[(currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()]) {
            throw new Error('No image found for the current language');
        }

        return (
            <div className={`${props.className} WrapperVideo`}>
                <video
                    controls={props.controls}
                    poster={props.poster}
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                    autoPlay
                    muted={props.muted}
                    loop
                    playsInline
                    ref={(ref) => {
                        if (props.videoRef) {
                            (props as any).videoRef.current = ref;
                        }
                        (localRef as any).current = ref;
                    }}
                    className={`${props?.classNameOnVideo}`}
                    key={(props.src as any)?.[currentLanguage || SupportedLanguage.EN]}
                >
                    {currentVideoToDisplay.webm && (
                        <source src={currentVideoToDisplay.webm} type="video/webm" />
                    )}
                    {currentVideoToDisplay.mp4 && (
                        <source src={currentVideoToDisplay.mp4} type="video/mp4" />
                    )}
                </video>
            </div>
        );
    } catch (error) {
        console.error('Error rendering image', error);
        return (
            <div className={`${props.className} WrapperVideo`}>
                <video autoPlay loop playsInline ref={props.videoRef} poster={props.poster}>
                    {currentVideoToDisplay.webm && (
                        <source src={currentVideoToDisplay.webm} type="video/webm" />
                    )}
                    {currentVideoToDisplay.mp4 && (
                        <source src={currentVideoToDisplay.mp4} type="video/mp4" />
                    )}
                </video>
            </div>
        );
    }
};

export default WrapperVideo;
