import classNames from 'classnames';
import styles from './index.module.scss';

type TextComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
type Variants = 'heading1' | 'body1';

interface TypographyProps {
    component: TextComponents;
    variant?: Variants;
    children: React.ReactNode;
}

export const Typography = ({ component, variant = 'body1', children }: TypographyProps) => {
    const Component = component;
    const className = classNames({
        [styles.heading1]: variant === 'heading1',
        [styles.body1]: variant === 'body1',
    });

    return <Component className={className}>{children}</Component>;
};
