import styles from './styles/job-faq.module.scss';
import sharedStyles from '../index.module.scss';
import { TabbedFAQs } from './TabbedFAQs';

const FAQS = [
    {
        id: 'tab1',
        title: 'How to Prepare',
        faqs: [
            {
                question: 'What positions are currently open?',
                answer: 'You can find a list of our current job openings on the careers page. We update it regularly, so check back often!',
            },
            {
                question: 'What is the application process like?',
                answer: "Applying is easy! Browse our job listings, click on the position that interests you, and submit your application through our online portal. We can't wait to hear from you! After submitting your application, our HR team will review it, and if your qualifications align with the position, we’ll reach out to schedule the first introductory interview.",
            },
            {
                question: 'How can I prepare for the interview?',
                answer: "We recommend researching our company values, mission, and social media platforms including www.spreeai.com, linkedin, instagram, etc. Be ready to discuss your relevant experiences and how they relate to the position you're applying for. Because we are a remote first company with a global workforce, we appreciate candidates who are able to demonstrate ability to work independently and build strong team synergy, have clear and engaging communication skills, and contribute to team initiatives with proactive energy and passion.",
            },
            {
                question: 'What is the interview process like?',
                answer: 'All of our interviews are conducted virtually. Depending on the role and circumstances, we also like to meet our candidates in-person.\n \n Stage 1: Introductory Interview \n Stage 2: Technical Interview\n Stage 3: Cultural Fit Interview \n Stage 4: Interview with the CEO\n Stage 5: Offer\n',
            },
        ],
    },
    {
        id: 'tab2',
        title: 'Company Culture',
        faqs: [
            {
                question: 'What are the company values?',
                answer: 'Our core values are kind, intelligence, creativity, and speed. Find out more in our career page!',
            },
            {
                question: 'What is the company culture like?',
                answer: 'We foster a collaborative, innovative, and proactive environment. Our values are centered around respect, humility, integrity, creativity, and growth. We prioritize a positive and inclusive culture where everyone feels valued.',
            },
            {
                question: 'What do employees say about working here?',
                answer: 'We encourage you to check our employee testimonials and reviews to get an authentic sense of our work environment and culture!',
            },
            {
                question: 'How does the company support diversity and inclusion?',
                answer: 'We celebrate diversity and are committed to fostering an inclusive environment. We have initiatives and programs in place to support underrepresented groups and create a welcoming workplace for all.',
            },
        ],
    },
    {
        id: 'tab3',
        title: 'Benefits',
        faqs: [
            {
                question: 'What benefits do you offer?',
                answer: 'We offer a comprehensive benefits package designed to support your overall well-being. This includes health insurance (medical, dental, vision, mental health resources, and other voluntary plans), retirement plan with employer match, unlimited paid time off, and our most popular benefit is being a remote first company. ',
            },
            {
                question: 'Is remote work an option?',
                answer: 'We are a fully remote environment. All employees work remotely.',
            },
        ],
    },
    {
        id: 'tab4',
        title: 'Additional',
        faqs: [
            {
                question: 'Can I apply for multiple positions?',
                answer: 'Absolutely! We encourage you to apply for any positions that match your skills and experience. Please read the job description and requirements carefully before submitting your application.',
            },
            {
                question: 'How can I stay updated on job openings?',
                answer: 'Follow us on linkedin to receive the latest updates about new openings. We also update our career page real-time.',
            },
        ],
    },
];

export const JobFAQ = () => {
    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionContent}>
                <div className={styles.sectionHeader}>
                    <h2 className={sharedStyles.heading2}>FAQs</h2>
                </div>
                <TabbedFAQs faqList={FAQS} />
            </div>
        </section>
    );
};
