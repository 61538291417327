import SelectInput from '../../../../Components/SelectInput';
import ArticleCard from '../../Components/ArticleCard';
import CategoryFilter from '../../Components/CategoryFilter';
import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UseServices from '../../../../Hooks/use-services';
import { ArticleDto } from '../../../../Services/Blog';
import BreadCrumb from '../../../../Components/BreadCrumb';
import Copydeck from '../../../../i18n/Copydeck';
import { useStores } from '../../../../Hooks/use-stores';

const BlogList = () => {
    const [currentFilter, setCurrentFilter] = useState<{
        value: string;
        label: string;
    }>({
        value: 'all',
        label: 'all',
    });
    const [currentSort, setCurrentSort] = useState<any>(null);
    const [articles, setArticles] = useState<ArticleDto[]>([]);
    const [isLoaded, setIsLoaded] = useState({
        defaultCurrentFilter: false,
    });
    const [defaultCurrentFilter, setDefaultCurrentFilter] = useState<{
        value: string;
        label: string;
    }>({
        value: 'all',
        label: 'all',
    });
    const { blogService } = UseServices();
    const { languageStore } = useStores();
    const { t } = useTranslation();

    const NUMBER_OF_ARTICLES = 25;

    const SORT_BY_OPTIONS = [
        {
            value: 'latest',
            label: Copydeck.blogPageSortByLatest,
        },
        {
            value: 'authorNameAsc',
            label: Copydeck.blogPageSortByAuthorNameAsc,
        },
        {
            value: 'authorNameDesc',
            label: Copydeck.blogPageSortByAuthorNameDesc,
        },
    ];

    const genereateCustomFilter = () => {
        if (currentSort) {
            if (currentSort.value === 'latest') {
                return '';
            }
        }

        return '';
    };

    const getTheLastestArticles = useCallback(async () => {
        try {
            const response =
                currentFilter.value === 'all'
                    ? await blogService.getMostRecentArticles(
                          NUMBER_OF_ARTICLES,
                          genereateCustomFilter(),
                          languageStore.currentLanguage
                      )
                    : await blogService.getAllArticlesInOneCategory(
                          NUMBER_OF_ARTICLES,
                          currentFilter.value,
                          genereateCustomFilter(),
                          languageStore.currentLanguage
                      );

            if (!response?.data?.[0]) {
                toast.error(t('BlogPage.fetch_error') as string);
            }

            if (!response?.data?.[0]) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            let sortedArticles = response.data;

            if (currentSort?.value.includes('authorName')) {
                sortedArticles.sort((a: any, b: any) => {
                    const authorA = a.attributes.authors.data[0].attributes.name || '';
                    const authorB = b.attributes.authors.data[0].attributes.name || '';
                    return currentSort.value.includes('Asc')
                        ? authorA.localeCompare(authorB)
                        : authorB.localeCompare(authorA);
                });
            }

            setArticles(sortedArticles);
        } catch (error) {
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter?.value, currentSort?.value]);

    useEffect(() => {
        if (!languageStore.currentLanguage) return;
        getTheLastestArticles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter?.value, currentSort?.value, languageStore.currentLanguage]);

    useEffect(() => {
        const getCategoryFilterInUrl = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const categoryId = urlParams.get('categoryId');
            const categoryName = urlParams.get('categoryName');

            if (categoryId && categoryName) {
                setCurrentFilter({
                    value: categoryId,
                    label: categoryName,
                });

                setDefaultCurrentFilter({
                    value: categoryId,
                    label: categoryName,
                });
            }

            setIsLoaded((prev) => ({
                ...prev,
                defaultCurrentFilter: true,
            }));
        };

        const timeout = setTimeout(() => {
            getCategoryFilterInUrl();
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    const onChangeFilter = (filter: { value: string; label: string }) => {
        setCurrentFilter(filter);
    };

    if (!articles) return null;

    return (
        <div className="BlogPageList">
            <div className="content">
                {isLoaded.defaultCurrentFilter && (
                    <CategoryFilter
                        defaultCurrentFilter={defaultCurrentFilter}
                        onChangeFilter={onChangeFilter}
                    />
                )}

                <div className="content-info">
                    <BreadCrumb currentFilter={currentFilter} />

                    <div className="title-type-sort text-main-header">
                        {currentSort?.label || SORT_BY_OPTIONS[0].label}
                    </div>
                </div>

                <SelectInput
                    options={SORT_BY_OPTIONS}
                    label="Sort by"
                    hiddenLabel
                    onChange={(value) => setCurrentSort(value as any)}
                    value={currentSort as any}
                    name="sortBy"
                />
            </div>

            <div className="list">
                {articles.map((article) => (
                    <ArticleCard key={article.id} article={article} />
                ))}
            </div>

            {/* <BlogPagination currentPage={currentPage} onPageChange={onPageChange} pageCount={10} /> */}
        </div>
    );
};

export default BlogList;
