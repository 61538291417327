import { motion } from 'framer-motion';

import { CustomCarousel } from '../../../Components/CustomCarousel';
import danelHeadShot from '../../../Assets/Img/Careers/testimonials/danel_testimonial.jpg';
import jeffHeadShot from '../../../Assets/Img/Careers/testimonials/jeff_graves_testimonial_2.jpg';
import camilaHeadShot from '../../../Assets/Img/Careers/testimonials/camila_chicas_3.jpg';
import bhamidipatiHeadShot from '../../../Assets/Img/Careers/testimonials/bhamidipati_shashanka.jpg';
import vinhTranHeadshot from '../../../Assets/Img/Careers/testimonials/vinh_tran_testimonial.webp';
import { useCustomCarousel } from '../../../Components/CustomCarousel/hooks/useCustomCarousel';
import { TestimonialItem } from './TestimonialItem';
import { CustomButtons } from '../../../Components/CustomCarousel/components/CustomButtons';

import styles from './styles/testimonials.module.scss';

export interface Testimonial {
    media: string;
    description: string;
    name: string;
    position: string;
}

const testimonials: Testimonial[] = [
    {
        media: danelHeadShot,
        description:
            'SpreeAI’s culture feels like a second home—an environment where everyone feels valued, motivated, and free to be themselves. It’s inspiring to collaborate with talented colleagues and build lasting friendships.',
        name: 'Danel',
        position: 'Jr. Designer',
    },
    {
        media: bhamidipatiHeadShot,
        description:
            "Remote work brings balance, letting me handle personal tasks, skip commutes, and work comfortably. Achieving our goals is a thrill—I can't wait to show my mom the product we're building!",
        name: 'Shashanka',
        position: 'Mobile SDK Developer',
    },
    {
        media: camilaHeadShot,
        description:
            'Working at an innovative company from home lets me watch my daughter grow while collaborating with a close-knit team. The drive to be first to market pushes me to excel daily, keeping us ahead of competitors.',
        name: 'Camila',
        position: 'Partnerships Manager',
    },
    {
        media: jeffHeadShot,
        description:
            "I treasure our team's global diversity—perfect for a travel enthusiast like me. Adapting to different needs and time zones fuels my growth and helps me secure Spree’s tech landscape with trust and passion.",
        name: 'Jeff',
        position: 'IT',
    },
    {
        media: vinhTranHeadshot,
        description:
            'I create algorithms for realistic clothing simulations that elevate virtual try-ons, inspired by SpreeAI’s ambitious goals to transform fashion. Contributing to this mission fuels my passion and makes my work feel truly impactful.',
        name: 'Vinh',
        position: 'Research Scientist',
    },
];

export const Testimonials = () => {
    return (
        <section className={styles.section}>
            <div className={styles.sectionWrapper}>
                <CustomCarousel
                    className={styles.carousel}
                    settings={{
                        arrows: false,
                        speed: 1000,
                        autoplay: true,
                        autoplaySpeed: 10000,
                        centerMode: true,
                        slidesToShow: 1,
                        infinite: true,
                        variableWidth: true,
                        adaptiveHeight: true,
                        appendDots: (dots) => {
                            return <CarouselControls dots={dots} />;
                        },
                    }}
                    showProgress
                    theme="light"
                >
                    {testimonials.map((testimonial, index) => (
                        <TestimonialItem key={index} testimonial={testimonial} index={index} />
                    ))}
                </CustomCarousel>
            </div>
        </section>
    );
};

interface CarouselControlsProps {
    dots: React.ReactNode;
}

const CarouselControls = ({ dots }: CarouselControlsProps) => {
    const { handleNext, handlePrev, theme } = useCustomCarousel();

    return (
        <div
            style={{
                bottom: '-100px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div className={styles.carouselControlsContainer}>
                <motion.ul layout className={styles.dotsList}>
                    {dots}
                </motion.ul>
                <CustomButtons handleNext={handleNext} handlePrev={handlePrev} theme={theme} />
            </div>
        </div>
    );
};
