import React from 'react';

import './index.scss';
import WrapperSection from '../../Components/WrapperSection';
import { TabbedFAQs } from '../Jobs/components/TabbedFAQs';
import Copydeck from '../../i18n/Copydeck';

const FAQPage = () => {
    const FAQs = [
        {
            id: 'tab1',
            title: 'Our Technology',
            faqs: [
                {
                    question: Copydeck.faqpageFaqQuestion1,
                    answer: Copydeck.faqpageFaqAnswer1,
                },
                {
                    question: Copydeck.faqpageFaqQuestion8,
                    answer: Copydeck.faqpageFaqAnswer8,
                },
                {
                    question: Copydeck.faqpageFaqQuestion2,
                    answer: Copydeck.faqpageFaqAnswer2,
                },
                {
                    question: Copydeck.faqpageFaqQuestion10,
                    answer: Copydeck.faqpageFaqAnswer10,
                },
            ],
        },
        {
            id: 'tab2',
            title: 'Getting Started',
            faqs: [
                {
                    question: Copydeck.faqpageFaqQuestion3,
                    answer: Copydeck.faqpageFaqAnswer3,
                },
                {
                    question: Copydeck.faqpageFaqQuestion9,
                    answer: Copydeck.faqpageFaqAnswer9,
                },
                {
                    question: Copydeck.faqpageFaqQuestion4,
                    answer: Copydeck.faqpageFaqAnswer4,
                },
                {
                    question: Copydeck.faqpageFaqQuestion5,
                    answer: Copydeck.faqpageFaqAnswer5,
                },
            ],
        },
        {
            id: 'tab3',
            title: 'About SpreeAI',
            faqs: [
                {
                    question: Copydeck.faqpageFaqQuestion6,
                    answer: Copydeck.faqpageFaqAnswer6,
                },
                {
                    question: Copydeck.faqpageFaqQuestion7,
                    answer: Copydeck.faqpageFaqAnswer7,
                    hasCustomHTML: true,
                },
            ],
        },
    ];

    return (
        <div className="FAQPage">
            <div className="hero">
                <WrapperSection.Title>FAQs</WrapperSection.Title>
            </div>
            <div className="wrapper-faq">
                <TabbedFAQs faqList={FAQs} />
            </div>
        </div>
    );
};

export default FAQPage;
