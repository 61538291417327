import { Link } from '../../../Components/Link';
import { Typography } from '../../../Components/Typography';
import CONSTANTS from '../../../Constants';
import heroImage from '../../../Assets/Img/Engineering/engineering-hero.jpg';

import styles from './styles/hero-section.module.scss';
import { Button2 as Button } from '../../../Components/Button2';

export const HeroSection = () => {
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.grid}>
                    <div className={styles.left}>
                        <Typography variant="heading1" component="h1">
                            Join Our Engineering Team: Build State-of-the-Art AI Solutions at
                            SpreeAI
                        </Typography>
                        <Typography variant="body1" component="p">
                            Be part of our mission to push boundaries in tech and fashion through
                            innovation, collaboration, and an AI-first mindset.
                        </Typography>
                        <div className={styles.buttons}>
                            <Button asChild variant="contained" color="secondary">
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.CAREERS }}>
                                    Join Our Remote Team
                                </Link>
                            </Button>
                            <Button asChild variant="outlined" color="secondary">
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.CAREERS }}>
                                    What Is SpreeAI
                                </Link>
                            </Button>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <picture>
                            <img className={styles.image} src={heroImage} alt="hero" />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    );
};
