import { motion } from 'framer-motion';

import { CustomCarousel } from '../../../Components/CustomCarousel';
import { useCustomCarousel } from '../../../Components/CustomCarousel/hooks/useCustomCarousel';
import { CustomButtons } from '../../../Components/CustomCarousel/components/CustomButtons';
import {
    Card,
    CoreValueCard,
} from '../../LifeAtSpreeAI/Sections/CoreValues/components/CoreValueCard';
import { Typography } from '../../../Components/Typography';

import styles from './styles/benefits-section.module.scss';
import { Section } from '../../../Components/Section';

const CARDS: Card[] = [
    {
        icon: 'video',
        title: 'Remote Flexibility',
        description:
            'Work from anywhere. We’re a remote-first company, giving you the freedom to choose your workspace.',
    },
    {
        icon: 'arrowSplit',
        title: 'Flexible Schedule',
        description:
            'Design your workday. Our flexible hours let you balance life and work seamlessly.',
    },
    {
        icon: 'laptop',
        title: 'Hardware Reimbursement',
        description:
            'We cover the cost of essential equipment, ensuring you have the tools you need to succeed.',
    },
    {
        icon: 'headset',
        title: 'Collaboration Tools',
        description:
            'Stay connected with our top-tier video and collaboration platforms for smooth remote teamwork.',
    },
    {
        icon: 'users',
        title: 'Intelligence',
        description: 'Join us for occasional in-person events to strengthen team bonds.',
    },
];

export const BenefitsSection = () => {
    return (
        <Section className={styles.sectionBackground}>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <Typography component="h2" variant="heading1">
                            Remote Work & Perks
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={styles.carouselContainer}>
                <div className={styles.overlay} />
                <CustomCarousel
                    className={styles.carousel}
                    settings={{
                        arrows: false,
                        speed: 1000,
                        autoplay: true,
                        autoplaySpeed: 10000,
                        slidesToShow: 1,
                        infinite: true,
                        variableWidth: true,
                        adaptiveHeight: true,
                        appendDots: (dots) => {
                            return <CarouselControls dots={dots} />;
                        },
                    }}
                    showProgress
                    theme="light"
                >
                    {CARDS.map((card, index) => (
                        <CoreValueCard
                            key={index}
                            index={index}
                            className={styles.card}
                            card={card}
                        />
                    ))}
                </CustomCarousel>
            </div>
        </Section>
    );
};

interface CarouselControlsProps {
    dots: React.ReactNode;
}

const CarouselControls = ({ dots }: CarouselControlsProps) => {
    const { handleNext, handlePrev, theme } = useCustomCarousel();

    return (
        <div className={styles.controlsContainer}>
            <div className={styles.carouselControls}>
                <motion.ul layout className={styles.dotsList}>
                    {dots}
                </motion.ul>
                <CustomButtons handleNext={handleNext} handlePrev={handlePrev} theme={theme} />
            </div>
        </div>
    );
};
