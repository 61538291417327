import { useContext } from 'react';
import { CustomCarouselContext } from '../index';

export const useCustomCarousel = () => {
    const { activeSlide, direction, handleNext, handlePrev, handleSetActiveSlide, theme } =
        useContext(CustomCarouselContext);

    return {
        activeSlide,
        direction,
        handleNext,
        handlePrev,
        handleSetActiveSlide,
        theme,
    };
};
