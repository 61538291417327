import React from 'react';

import CONSTANTS from '../../../Constants';
import placeholerImage from '../../../Assets/Img/Careers/placeholder.png';
import Button from '../../../Components/Button';

import styles from './styles/life-at-spree.module.scss';
import sharedStyles from '../index.module.scss';
import { Link } from '../../../Components/Link';

export const LifeAtSpree = () => {
    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
                <div className={styles.details}>
                    <h2 className={sharedStyles.heading2}>Life at SpreeAI</h2>
                    <p className={sharedStyles.body1}>
                        SpreeAI is a fast-growing, innovative AI technology company with a core
                        focus on revolutionizing the shopping experience with our cutting-edge
                        Software-as-a-Service products. We thrive in a dynamic, fast-paced
                        environment where creativity meets technology.
                    </p>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.MEET_OUR_TEAM }}>
                        <Button type="secondary" text="Get To Know Our Team" />
                    </Link>
                </div>
                <div className={styles.image}>
                    <img
                        src={placeholerImage}
                        alt="Person having virtual meeting with others on tablet"
                        loading="lazy"
                    />
                </div>
            </div>
        </section>
    );
};
