import React from 'react';
import { BlockDto } from '../../../Services/Blog';
import './index.scss';

interface MediaStrapiProps {
    block: BlockDto;
}

const MediaStrapi: React.FC<MediaStrapiProps> = ({ block }) => {
    const url = (block as any)?.file?.data?.attributes?.url;

    if (url.includes('mp4')) {
        return (
            <div className="MediaStrapi video-container">
                <video
                    src={url}
                    controls
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                />
            </div>
        );
    }

    return (
        <div className="MediaStrapi img-container">
            <img src={url} alt="strapi img" loading="lazy" />
        </div>
    );
};

export default MediaStrapi;
