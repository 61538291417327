import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useCustomCarousel } from '../../../../../Components/CustomCarousel/hooks/useCustomCarousel';

import styles from './core-value-card.module.scss';
import {
    ArrowsSplit,
    Brain,
    CheckFat,
    Clock,
    Headset,
    Heart,
    Icon,
    Laptop,
    Lightbulb,
    UsersThree,
    VideoConference,
} from '@phosphor-icons/react';

type IconType =
    | 'check'
    | 'heart'
    | 'brain'
    | 'lightbulb'
    | 'clock'
    | 'video'
    | 'arrowSplit'
    | 'laptop'
    | 'headset'
    | 'users';

type Icons = {
    [key in IconType]: Icon;
};

export interface Card {
    description: string;
    icon: IconType;
    title: string;
}

export interface CoreValueCardsProps {
    card: Card;
    className?: string;
    index: number;
}

export const CoreValueCard = ({
    card: { title, description, icon },
    className,
    index,
}: CoreValueCardsProps) => {
    const [dataIndex, setDataIndex] = useState<number | null>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const { activeSlide, handleSetActiveSlide } = useCustomCarousel();

    const isActive = activeSlide === index;

    useEffect(() => {
        if (cardRef.current) {
            if (cardRef.current.parentElement?.parentElement?.dataset.index) {
                setDataIndex(
                    parseFloat(cardRef.current.parentElement?.parentElement?.dataset.index)
                );
            }
        }
    }, [cardRef]);

    const IconMap: Icons = {
        arrowSplit: ArrowsSplit,
        brain: Brain,
        check: CheckFat,
        clock: Clock,
        headset: Headset,
        heart: Heart,
        laptop: Laptop,
        lightbulb: Lightbulb,
        users: UsersThree,
        video: VideoConference,
    };

    const Icon = IconMap[icon];

    const handleOnClick = () => {
        if (dataIndex !== null) {
            handleSetActiveSlide(dataIndex);
        }
    };

    return (
        <article
            ref={cardRef}
            className={classNames(styles.card, { [styles.active]: isActive }, className)}
            onClick={handleOnClick}
        >
            <div className={styles.background} />
            <div className={styles.icon}>
                <div className={styles.outlinedIcon}>
                    <Icon fill="#2b2b2b" size={40} />
                </div>
                <div className={styles.filledIcon}>
                    <Icon fill="#2EB67D" weight="duotone" size={40} />
                </div>
            </div>
            <div className={styles.content}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </article>
    );
};
