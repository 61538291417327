import React from 'react';
import { useTranslation } from 'react-i18next';
import './layout-footer.scss';
import { LogoBrand } from './logo-brand';
import { InstagramIcon } from '../../Icons/instagram-icon';
import { TwitterIcon } from '../../Icons/twitter-icon';
import CONSTANTS from '../../Constants';
import { LinkedinIcon } from '../../Icons/linkedin-icon';
import TiktokIcon from '../../Icons/tiktok-icon';
import YoutubeIcon from '../../Icons/youtube-icon';
import { useStores } from '../../Hooks/use-stores';
import { Link } from '../../Components/Link';
import { BlueSkyIcon } from '../../Icons/BlueSkyIcon';
import { ThreadsIcon } from '../../Icons/ThreadsIcon';

const LayoutFooter = () => {
    const { t } = useTranslation();
    const { cookiesStore } = useStores();

    const onClickModalSettings = () => {
        cookiesStore.modalIsVisible = true;
    };

    return (
        <footer className="LayoutFooter">
            <div className="top-section">
                <Link pathConfig={{ where: CONSTANTS.SCREEN.HOME }}>
                    <div className="cursor-pointer">
                        <LogoBrand />
                    </div>
                </Link>

                <nav className="links-container">
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.CONTACT_US }}>
                        <span className="cursor-pointer text-body-primary-font">
                            {t('nav_contact_us')}
                        </span>
                    </Link>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.TERMS_OF_SERVICE }}>
                        <span className="cursor-pointer text-body-primary-font">
                            {t('nav_terms_of_service')}
                        </span>
                    </Link>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.PRIVACY_NOTICE }}>
                        <span className="cursor-pointer text-body-primary-font">
                            {t('nav_privacy_policy')}
                        </span>
                    </Link>

                    <div
                        className="btn-delete-default-style cursor-pointer text-body-primary-font"
                        onClick={onClickModalSettings}
                    >
                        {t('coookie_modal_navigation')}
                    </div>
                </nav>

                <div className="social-media-container">
                    <a href={CONSTANTS.INSTAGRAM_URL} target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                    <a href={CONSTANTS.LINKEDIN_URL} target="_blank" rel="noreferrer">
                        <LinkedinIcon fill="#2b2b2b" />
                    </a>
                    <a href={CONSTANTS.TWITTER_URL} target="_blank" rel="noreferrer">
                        <TwitterIcon fill="#2b2b2b" />
                    </a>
                    <a href={CONSTANTS.THREADS_URL} target="_blank" rel="noreferrer">
                        <ThreadsIcon />
                    </a>
                    <a href={CONSTANTS.BLUE_SKY_URL} target="_blank" rel="noreferrer">
                        <BlueSkyIcon />
                    </a>
                    <a href={CONSTANTS.TIKTOK_URL} target="_blank" rel="noreferrer">
                        <TiktokIcon />
                    </a>
                    <a href={CONSTANTS.YOUTUBE_URL} target="_blank" rel="noreferrer">
                        <YoutubeIcon />
                    </a>
                </div>
            </div>

            <div className="bottom-section text-white">
                <div className="inner">
                    <span style={{ textAlign: 'center' }}>
                        {t('copyright_footer')} {new Date().getFullYear()}
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default LayoutFooter;
