import styles from './index.module.scss';
import { AccountDeletedIcon } from '../../Icons/AccountDeleted';
import { LogoBrand } from '../../Layout/Components/logo-brand';
import Copydeck from '../../i18n/Copydeck';

export const AccountDeleted = () => {
    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <LogoBrand />
                </div>
                <article className={styles.article}>
                    <AccountDeletedIcon />
                    <h1>{Copydeck.accountDeletedTitleText}</h1>
                    <div className={styles.divider} />
                    <p>{Copydeck.accountDeletedBodyText1}</p>
                    <p>{Copydeck.accountDeletedBodyText2}</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.accountDeletedBodyText3,
                        }}
                    />
                </article>
            </div>
        </main>
    );
};
