import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { CustomCarousel } from '../../../../Components/CustomCarousel';
import { useCustomCarousel } from '../../../../Components/CustomCarousel/hooks/useCustomCarousel';
import { CustomButtons } from '../../../../Components/CustomCarousel/components/CustomButtons';
import styles from './index.module.scss';
import { Card, CoreValueCard } from './components/CoreValueCard';

export const CoreValuesSection = () => {
    const { t } = useTranslation();

    const CARDS: Card[] = [
        {
            icon: 'check',
            title: t('AboutUsPage.core_value_5_title'),
            description: t('AboutUsPage.core_value_5_desc'),
        },
        {
            icon: 'heart',
            title: t('AboutUsPage.core_value_1_title'),
            description: t('AboutUsPage.core_value_1_desc'),
        },
        {
            icon: 'brain',
            title: t('AboutUsPage.core_value_2_title'),
            description: t('AboutUsPage.core_value_2_desc'),
        },
        {
            icon: 'lightbulb',
            title: t('AboutUsPage.core_value_3_title'),
            description: t('AboutUsPage.core_value_3_desc'),
        },
        {
            icon: 'clock',
            title: t('AboutUsPage.core_value_4_title'),
            description: t('AboutUsPage.core_value_4_desc'),
        },
    ];

    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <h2>{t('AboutUsPage.core_value_title')}</h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('AboutUsPage.core_value_desc'),
                            }}
                        ></p>
                    </div>
                </div>
            </div>

            <div className={styles.carouselContainer}>
                <div className={styles.overlay} />
                <CustomCarousel
                    className={styles.carousel}
                    settings={{
                        arrows: false,
                        speed: 1000,
                        autoplay: true,
                        autoplaySpeed: 10000,
                        slidesToShow: 1,
                        infinite: true,
                        variableWidth: true,
                        adaptiveHeight: true,
                        appendDots: (dots) => {
                            return <CarouselControls dots={dots} />;
                        },
                    }}
                    showProgress
                    theme="light"
                >
                    {CARDS.map((card, index) => (
                        <CoreValueCard key={index} index={index} card={card} />
                    ))}
                </CustomCarousel>
            </div>
        </section>
    );
};

interface CarouselControlsProps {
    dots: React.ReactNode;
}

const CarouselControls = ({ dots }: CarouselControlsProps) => {
    const { handleNext, handlePrev, theme } = useCustomCarousel();

    return (
        <div className={styles.controlsContainer}>
            <div className={styles.carouselControls}>
                <motion.ul layout className={styles.dotsList}>
                    {dots}
                </motion.ul>
                <CustomButtons handleNext={handleNext} handlePrev={handlePrev} theme={theme} />
            </div>
        </div>
    );
};
