import classNames from 'classnames';
import styles from './index.module.scss';

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
}

export const Section = ({ children, ...props }: SectionProps) => {
    const classes = classNames(styles.section, props.className);

    return (
        <section {...props} className={classes}>
            {children}
        </section>
    );
};
