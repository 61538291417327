import React from 'react';

import './index.scss';
import LifeAtSpreeAISectionHero from './Sections/Hero';
import LifeAtSpreeAISectionRemote from './Sections/Remote';
import LifeAtSpreeAISectionInclusionDiversity from './Sections/InclusionDiversity';
import Button from '../../Components/Button';
import Copydeck from '../../i18n/Copydeck';
import CONSTANTS from '../../Constants';
import LifeAtSpreeAIPageSectionBenefits from './Sections/Benefits';
import WrapperSection from '../../Components/WrapperSection';
import LatestUpdates from '../Blog/id/Sections/LatestUpdates';
import { CoreValuesSection } from './Sections/CoreValues';
import { Link } from '../../Components/Link';

const LifeAtSpreeAIPage = () => {
    React.useEffect(() => {
        const anchor = window.location.href.split('#')[1];

        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                setTimeout(() => {
                    anchorEl.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        }
    }, [window.location.pathname]);

    return (
        <div className="LifeAtSpreeAIPage">
            <LifeAtSpreeAISectionHero />
            <CoreValuesSection />
            <LifeAtSpreeAISectionRemote />
            <div id="benefits">
                <LifeAtSpreeAIPageSectionBenefits />
            </div>
            <LifeAtSpreeAISectionInclusionDiversity />
            <LatestUpdates />
            <div className="container-join-team">
                <WrapperSection.Title htmlTag="h2" className="capitalize">
                    {Copydeck.lifeAtSpreeAipageJoinTheTeamTitle}
                </WrapperSection.Title>

                <Link pathConfig={{ where: CONSTANTS.SCREEN.CAREERS }}>
                    <Button
                        type="secondary"
                        text={Copydeck.lifeAtSpreeAipageJoinTheTeamCtaCareers}
                    />
                </Link>
            </div>
        </div>
    );
};

export default LifeAtSpreeAIPage;
