import Service from '..';

import i18n from 'i18next';
import { SupportedLanguage } from '../../Stores/LanguageStore';

export interface PaginationDto {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

export interface AuthorDto {
    id: string;
    attributes: {
        createdAt: string;
        email: string;
        name: string;
        updatedAt: string;
    };
}

export interface CategoryDto {
    id: string;
    attributes: {
        createdAt: string;
        name: string;
        updatedAt: string;
        slug: string;
        description: string;
        primaryColor: string;
        backgroundColor: string;
        articles: {
            data: ArticleDto[];
        };
    };
}

export interface CoverDtoFormats {
    small: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
        sizeInBytes: number;
    };
    medium: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
        sizeInBytes: number;
    };
    large: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
        sizeInBytes: number;
    };
    thumbnail: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
        sizeInBytes: number;
    };
}

export interface CoverDto {
    id: string;
    attributes: {
        name: string;
        alternativeText: string;
        caption: string;
        width: number;
        height: number;
        formats: CoverDtoFormats;
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: null;
        provider: string;
        provider_metadata: null;
        createdAt: string;
        updatedAt: string;
    };
}

export interface BlockDto {
    body: string;
    id: string;
    __component: 'shared.rich-text' | 'shared.media';
}

export interface ArticleDto {
    id: string;
    attributes: {
        authors: {
            data: AuthorDto[];
        };
        blocks: BlockDto[];
        category: {
            data: CategoryDto;
        };
        cover: {
            data: CoverDto;
        };
        coverMobile: {
            data: CoverDto | null;
        };
        createdAt: string;
        description: null;
        publishedAt: string;
        slug: string;
        title: string;
        updatedAt: string;
        oembed: string;
    };
}

export interface GetAllArticlesResponseDto {
    data: ArticleDto[];
    meta: {
        pagination: PaginationDto;
    };
}

export interface GetDetailArticleResponseDto {
    data: any;
    meta: {};
}

export interface GetAllCategoriesResponseDto {
    data: CategoryDto[];
    meta: {
        pagination: PaginationDto;
    };
}

interface BlogServiceInterface {
    getAllArticles(): Promise<GetAllArticlesResponseDto>;
    getOneArticle(id: string, language: string): Promise<GetDetailArticleResponseDto>;
}

class BlogService extends Service implements BlogServiceInterface {
    private buildUrl(url: string): string {
        return `https://superb-actor-37d8b69f79.strapiapp.com/api/${url}`;
    }

    public async getAllCategories(): Promise<GetAllCategoriesResponseDto> {
        const response = await this.get(this.buildUrl('categories?populate=*'), true);

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as GetAllCategoriesResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    public async getAllArticlesInOneCategory(
        limit: number,
        categoryId: string,
        customSort?: string,
        language: SupportedLanguage | null = SupportedLanguage.EN
    ): Promise<GetAllArticlesResponseDto> {
        const response = await this.get(
            this.buildUrl(
                `articles?locale=${language?.toLocaleLowerCase()}&populate=*&filters[category][id][$eq]=${categoryId}&pagination[limit]=${limit}` +
                    customSort
            ),
            true
        );

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as GetAllArticlesResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    public async getAllArticles(): Promise<GetAllArticlesResponseDto> {
        const response = await this.get(
            this.buildUrl('articles?populate=*&pagination[limit]=9'),
            true
        );

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as GetAllArticlesResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    public async getOneArticle(id: string, language: string): Promise<GetDetailArticleResponseDto> {
        const response = await this.get(
            this.buildUrl(
                'articles/' +
                    id +
                    `?populate[blocks][populate]=*&populate[cover]=*&populate[coverMobile]=*&populate[category]=*$populate[authors]=*&locale=${language.toLowerCase()}`
            ),
            true
        );

        const response2 = await this.get(
            this.buildUrl('articles/' + id + '?populate[authors]=*'),
            true
        );

        if (!response || !response2) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            const repsonse2Json = await response2.json();

            return {
                ...responseJson,
                data: {
                    ...responseJson.data,
                    attributes: {
                        ...responseJson.data.attributes,
                        authors: repsonse2Json.data.attributes.authors,
                    },
                },
                meta: {},
            } as GetDetailArticleResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    public async getMostRecentArticles(
        limit: number,
        customSort?: string,
        language: SupportedLanguage | null = SupportedLanguage.EN
    ): Promise<GetAllArticlesResponseDto> {
        const sort = customSort ? customSort : 'sort[0]=createdAt:desc';

        const response = await this.get(
            this.buildUrl(
                `articles?populate=*&pagination[limit]=${limit}&${sort}&locale=${language?.toLocaleLowerCase()}`
            ),
            true
        );

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as GetAllArticlesResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }
}

export default BlogService;
