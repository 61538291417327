export const BlueSkyIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                d="M5.892 3.43001C4.112 2.37001 2.079 3.87601 2.262 5.78901L2.66 9.97401C2.836 11.829 4.5 14 6.855 13.797C4.5 14.5 3.132 16.365 4.88 18.645C5.166 19.018 5.477 19.39 5.794 19.707C6.111 20.024 6.484 20.335 6.856 20.621C8.736 22.062 11.231 19.971 11.911 17.931L12.001 17.663L12.091 17.932C12.771 19.972 15.265 22.062 17.146 20.621C17.518 20.335 17.891 20.024 18.208 19.707C18.525 19.39 18.836 19.017 19.122 18.645C20.869 16.365 19.501 14.5 17.146 13.797C19.501 14 21.164 11.829 21.341 9.97401L21.74 5.78901C21.922 3.87601 19.888 2.37101 18.109 3.42901C15.2 5.16001 13.485 7.89301 12 10.402C10.515 7.89201 8.8 5.16001 5.892 3.43001Z"
                fill="#2B2B2B"
            />
        </svg>
    );
};
