import { BenefitsSection } from './components/BenefitsSection';
import { HeroSection } from './components/HeroSection';

import styles from './index.module.scss';

export const EngineeringPage = () => {
    return (
        <main className={styles.main}>
            <HeroSection />
            <BenefitsSection />
        </main>
    );
};
